<template>
    <div class="grid">
        <div class="col-2">
            <div class="card mb-0">
				<Listbox v-model="selectedNumber" :options="numbers"  listStyle="height:calc(100vh - 9.3rem)" />
			</div>
        </div>
        <div class="col-10">
            <div class="card mb-0">
				<DataTable stripedRows scrollable scrollHeight="calc(100vh - 13.2rem)" :value="messages" :paginator="true" :rowsPerPageOptions="[10,20,50]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="短信 {first}-{last} ，总数：{totalRecords}" class="p-datatable-customers" :rows="20"
                dataKey="fnsku"  :loading="loading" responsiveLayout="scroll">
                <template #empty>
                    {{isempty}}
                </template>
                <Column field="send_no" header="发送号码" style="min-width:1rem">
                    <template #body="{data}">
                        {{data.send_no}}
                    </template>
                </Column>
                <Column field="secret_no" header="接收号码" style="min-width:1rem">
                    <template #body="{data}">
                        {{data.secret_no}}
                    </template>
                </Column>
                <Column field="start_time" header="接收时间" style="min-width:1rem">
                    <template #body="{data}">
                        {{data.start_time}}
                    </template>
                </Column>
                <Column field="sms_content" header="信息内容" style="min-width:1rem">
                    <template #body="{data}">
                        {{data.sms_content}}
                    </template>
                </Column>
            </DataTable>
			</div>
        </div>
    </div>
</template>

<script>
import Service from '../service/Service';
export default {
    data(){
        return{
        selectedNumber:null,
        numbers: ['最新10条短信'],
        messages:null,
        loading: true,
        isempty:'正在加载...'
        }
    },
    created() {
        this.service = new Service();
    },
    mounted(){
            var url='system/getnumberlist.php';
            this.service.sendHttp(url).then(res=>res.json()).then(data => {
                data.forEach(number=>{
                    this.numbers.push(number);
                });
                this.selectedNumber='最新10条短信';
            });
        },
    watch:{
        selectedNumber:function(){
                this.loading=true;
                var number=this.selectedNumber;
                if(number=='最新10条短信')
                number='messageten';
                var url='system/getmessagelist.php?number='+number;
                this.service.sendHttp(url).then(res=>res.json()).then(data => {
                    if(data.result=='success'){
                        this.loading=false;
                        this.messages = data.messages;
                        this.isempty='无数据!';
                    }
                
            });
            }
    },
}
</script>